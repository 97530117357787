/** This Source Code Form is subject to the terms of the Mozilla Public
  License, v. 2.0. If a copy of the MPL was not distributed with this
  file, You can obtain one at http://mozilla.org/MPL/2.0/.
*/
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';

const FloatingHeader = dynamic(() => import('./FloatingHeader'), {
  ssr: false,
});

function DefaultLayout({
  children,
  hideFooter,
}: {
  children: React.ReactNode;
  hideFooter?: boolean;
}) {
  return (
    <>
      <Head>
        <title>mevETH - Ethereum LSD Protocol</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        {/* add support for mstile-144x144.png */}

        <meta property="og:url" content="https://mev.io" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="mevETH - Ethereum LSD Protocol" />
        <meta
          property="og:description"
          content="Ethereum's next-generation seamless liquid staking protocol. Built from the ground up for MEV capture and Validator rewards."
        />
        <meta property="og:image" content="https://mev.io/images/twitter-card.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mevdotio" />
        <meta name="twitter:creator" content="@mevdotio" />
        <meta name="twitter:title" content="mevETH - Ethereum LSD Protocol" />
        <meta
          name="twitter:description"
          content="Ethereum's next-generation seamless liquid staking protocol. Built from the ground up for MEV capture and Validator rewards."
        />
        <meta name="twitter:image" content="https://mev.io/images/twitter-card.png" />
        {/* Defining the meta viewport */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Main>
        <FloatingHeader />
        {children}
        <Footer hideFooter={hideFooter} />
      </Main>
    </>
  );
}

const Main = styled.main`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  min-width: 100vw;
`;

export default DefaultLayout;
