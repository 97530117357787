import { colors, device } from '@/styles/common';
import Link from 'next/link';
import styled from 'styled-components';

function AvailableToday() {
  return (
    <AvailableTodayContainer>
      <Title>
        Join the mevETH revolution today
        <br />
        {/** TODO */}
      </Title>
      <StakeNowButton href="/stake">
        <StakeNowText>mint mevETH</StakeNowText>
      </StakeNowButton>
      <Subtitle>
        29,000+ ETH and counting
        <br />
      </Subtitle>
    </AvailableTodayContainer>
  );
}

const AvailableTodayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 72px;
  padding-bottom: 80px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 54px;
  line-height: 1.3;

  text-align: center;

  ${colors.whiteShine}

  @media ${device.mobileL} {
    font-size: 48px;
  }
  @media ${device.mobileM} {
    font-size: 42px;
  }
  @media ${device.mobileS} {
    font-size: 32px;
  }
`;

const Subtitle = styled.div`
  max-width: 364px;

  font-weight: 400;
  font-size: 19px;
  line-height: 1.5;
  text-align: center;

  ${colors.grayShine}

  @media ${device.mobileL} {
    max-width: unset;
    padding: 0 16px;
    font-size: 18px;
  }
  @media ${device.mobileM} {
    font-size: 16px;
  }
  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

// Stake Now Button
const StakeNowButton = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 12px 4px rgba(255, 255, 255, 0.25);

  padding: 16px 32px;

  &:hover {
    cursor: pointer;
    transform: scale(0.975);
  }
  transition: transform 0.225s ease-in-out;

  margin-top: 32px;
  margin-bottom: 24px;

  @media ${device.mobileL} {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  @media ${device.mobileM} {
    margin-top: 20px;
    margin-bottom: 12px;
  }
`;

const StakeNowText = styled.div`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;

  color: #000000;
`;

export default AvailableToday;
