import { device } from '@/styles/common';
import Spline from '@splinetool/react-spline';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import StakingButton from '../general/StakingButton';

const splineUrl = 'https://prod.spline.design/l6IIuP2k6FsAfyjs/scene.splinecode';

function HeroTopFold() {
  function onLoad(splineApp: any) {
    splineApp.emitEvent('keyDown', 'a7186aec-6618-4997-b318-ef021f5cc885');
  }

  return (
    <TopFold>
      <HeaderContainer>
        <Link href="/">
          <img
            onClick={() => {
              window.location.href = '/';
            }}
            src="/svg/MEV Text White.svg"
            alt="Manifold LSD"
          />
        </Link>
        {/* TODO Made sticky on scroll! */}
        <StakingButton href="/stake">Start staking ETH</StakingButton>
      </HeaderContainer>
      <MainContent>
        <LeftContainer>
          <div>MEV Optimized Liquid Staking</div>
          <div>
            Ethereum's next-generation seamless liquid staking protocol. Built from the ground up
            for MEV capture and Validator rewards.
          </div>
          <LearnMoreButton href="#about">Learn More</LearnMoreButton>
        </LeftContainer>
        <SplineContainer>
          <Spline onLoad={onLoad} scene={splineUrl} />
        </SplineContainer>
      </MainContent>
    </TopFold>
  );
}

const TopFold = styled.div`
  position: relative;

  background: radial-gradient(
      135% 135% at 50% 10.01%,
      rgba(62, 237, 249, 0) 38.02%,
      rgba(62, 237, 249, 0.000296296) 38.23%,
      rgba(62, 237, 249, 0.00237037) 38.86%,
      rgba(62, 237, 249, 0.008) 39.9%,
      rgba(63, 237, 249, 0.018963) 41.37%,
      rgba(64, 237, 249, 0.037037) 43.25%,
      rgba(66, 238, 249, 0.064) 45.55%,
      rgba(68, 238, 250, 0.10163) 48.26%,
      rgba(71, 238, 250, 0.151704) 51.39%,
      rgba(75, 239, 250, 0.216) 54.93%,
      rgba(80, 240, 251, 0.296296) 58.89%,
      rgba(86, 241, 251, 0.39437) 63.27%,
      rgba(93, 242, 252, 0.512) 68.05%,
      rgba(101, 243, 253, 0.650963) 73.25%,
      rgba(111, 245, 254, 0.813037) 78.87%,
      #7af6ff 84.9%
    ),
    #000000;

  border-bottom-left-radius: 38px;
  border-bottom-right-radius: 38px;
  width: calc(100vw - 48px);
  height: calc(100vh - 8px);
  align-self: center;
  overflow: hidden;

  @media ${device.mobileL} {
    width: 100vw;
    height: auto;
    min-height: 100vh;
  }

  @media ${device.mobileM} {
    min-height: unset;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  margin-top: 24px;
  img {
    width: 136px;
    cursor: pointer;
  }
  width: 100%;
  z-index: 105;
`;

// Main
const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 90%;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  min-height: 90vh;

  gap: 8px;
  padding-left: 7.5%;

  > div:first-child {
    font-size: 70px;
    font-weight: 500;
    max-width: 590px;
  }

  > div:nth-child(2) {
    max-width: 550px;
    font-size: 16px;
    font-weight: 400;
    color: #ececfa;
    line-height: 1.75;
  }

  @media ${device.mobileL} {
    padding: 0;
    padding-top: 5%;
    min-height: auto;
    max-width: 70vw;

    align-items: center;

    > div:first-child {
      max-width: unset;
      text-align: center;
    }

    > div:nth-child(2) {
      text-align: center;
      max-width: unset;
    }
  }

  @media ${device.mobileM} {
    padding-top: 12%;
    max-width: 85vw;
    > div:first-child {
      font-size: 50px;
    }
  }

  @media ${device.mobileS} {
    padding-top: 18%;
    > div:first-child {
      font-size: 32px;
    }
    > div:nth-child(2) {
      font-size: 14px;
      line-height: 1.4;
    }

`;

const LearnMoreButton = styled.a`
  background: #ffffff;
  color: #000000;
  border-radius: 20px;
  height: 38px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  cursor: pointer;

  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
`;

const SplineContainer = styled.div`
  height: 100%;
  width: 50vw;
  @media ${device.mobileL} {
    margin-top: 32px;
    width: 100vw;
    height: 50vh;
  }

  @media ${device.mobileM} {
    margin-top: 0;
  }
`;

export default HeroTopFold;
